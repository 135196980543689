import './App.css';
import Homescreen from './screens/Homescreen';

function App() {
  return (
    <div className="App">
      <div class=" py-3 text-white text-4xl text-center drop-shadow-2xl bg-gradient-to-tr from-slate-400 via-zinc-500  to-gray-400">
          <h2>
            Dashboard
          </h2>
      </div>
      <div class=" py-8">
        <Homescreen/>
      </div>
    </div>
    
    
  );
}

export default App;
