import React from "react";
import LineChartScreen from "../charts/LineChartScreen";


const Homescreen = () =>{
    return(
        <div class=" container mx-auto">
            <div>
                <h2 class=" text-2xl text-center">
                    Capterra Clicks line Chart
                </h2>
            </div>
            <div class=" w-full mx-auto py-3">
                <LineChartScreen />
            </div>
        </div>
    );
}

export default Homescreen;