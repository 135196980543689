import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { getdata } from "../data/apiData";

import axios from '../data/axios';

// const cors = require('cors')

const LineChartScreen = () => {

    // LineChartScreen.use(cors())

    const [labelsData, setLabelsData] = useState([]);
    const [clicksData, setClicksData] = useState([]);



    const data = (canvas) => {
        const caplc = canvas.getContext('2d');
        const gradient = caplc.createLinearGradient(241, 36, 180, 0.8);

        gradient.addColorStop(0, '#4a4a4a');
        gradient.addColorStop(0.6, '#333adf');
        gradient.addColorStop(1, '#1f4add');

        return {
            labels: labelsData,
            datasets: [
                {
                    fill: false,
                    label: 'clicks',
                    data: clicksData,
                    backgroundColor: gradient,
                    borderColor: gradient,
                    borderWidth: 3
                }
            ]
        }
    }

    const options = {
        responsive: true,
        tooltips: {
            mode: 'index',
            intersect: false,
        },
        hover: {
            mode: 'nearest',
            intersect: true
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontColor: 'rgba(222, 222, 220, 1)'
            }
        },
    }

    const getChartData = async () => {
        console.log('test');
        let config = {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'ZCzf5cikCqJmgwN7',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            }
        };

        axios.get('/clicks', config).then((data) => {
            console.log(data);
        }).catch((err) => {
            console.log(err);
        })

        try {
            let labelsArray = [];
            let clicksArray = [];
            const response = await getdata();
            response.forEach(element => {
                labelsArray.push(element.date_of_report);
                clicksArray.push(element.clicks);
            });
            setLabelsData(labelsArray);
            setClicksData(clicksArray);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getChartData();
    }, []);
    return (
        <Line data={data} options={options} />
    );
}

export default LineChartScreen;